import React from 'react';
import { useTranslation } from 'react-i18next';

function Overview() {
    const { t } = useTranslation();
    return (
        <section
            className='overview-section bg-grey section-padding'
            id='overview'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <div className='overview-content'>
                            <h2>
                                {t('Defining the Future with the Blockchain')}
                            </h2>
                            <p>
                                {t(
                                    'Solichain delivers innovative Web 3 solutions to propel your business into the digital era'
                                )}
                            </p>
                            <ul className='features-list'>
                                <li>
                                    <span className='active'>
                                        {t('Smart Contract Development')}
                                    </span>
                                </li>
                                <li>
                                    <span>{t('dApp Creation')}</span>
                                </li>
                                <li>
                                    <span>{t('DeFi Solutions')}</span>
                                </li>
                                <li>
                                    <span>{t('DAO Structures')}</span>
                                </li>
                                <li>
                                    <span>{t('Tokenomics')}</span>
                                </li>
                                <li>
                                    <span>{t('Tokenization & NFTs')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='overview-image-2'>
                            <img src='assets/img/overview.webp' alt='image' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Overview;
