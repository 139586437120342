import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    title: string;
    content: string;
    link: string;
    iconClass: string;
}

function Service(props: IProps) {
    const { t } = useTranslation();
    return (
        <div className='single-services-item'>
            <div className='services-icon'>
                <i className={props.iconClass}></i>
            </div>
            <h3>{props.title}</h3>

            <p dangerouslySetInnerHTML={{ __html: props.content }}></p>

            <div className='services-btn-link'>
                <a href='#0' className='services-link'>
                    {t('Read More')}
                </a>
            </div>
        </div>
    );
}

export default Service;
