import React from 'react';
import Services from '../organisms/Services';
import Overview from '../organisms/Overview';
// import Counter from '../organisms/Counter';
// import Team from '../organisms/Team';
// import Testimonial from '../organisms/Testimonial';
import ContactUs from '../organisms/ContactUs';
// import Partnership from '../organisms/Partnership';
import Blogs from '../organisms/Blogs';
import Footer from '../organisms/Footer';
import Home from '../organisms/Home';
import { useTranslation } from 'react-i18next';

function Lending() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    return (
        <>
            {/* // <!-- Start Preloader Area --> */}
            {/* <div className='preloader'>
                <div className='d-table'>
                    <div className='d-table-cell'>
                        <div className='lds-spinner'>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* // <!-- End Preloader Area --> */}
            {/* <!-- Start Navbar Area --> */}
            {/* <!-- End Navbar Area --> */}
            {/* <!-- Start Home Area --> */}
            <Home />
            {/* <!-- End Home Area --> */}
            {/* <!-- Start About Section --> */}
            <section className='counter-area section-padding' id='about-us'>
                <div className='container'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-5 col-md-12 col-sm-12'>
                            {/* <div className='about-image'> */}
                            <div>
                                <img
                                    src='assets/img/about-us.svg'
                                    alt='image'
                                    className={`english-mode-lending-image
                                        ${
                                            i18n.language == 'fr'
                                                ? 'french-mode-lending-image'
                                                : ''
                                        }
                                            `}
                                />
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12 col-sm-12'>
                            <div className='about-content'>
                                <div className='about-content-text'>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h2>
                                            {t(
                                                'Comprehensive Blockchain Standards'
                                            )}
                                        </h2>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "At <b>Solichain</b>, we master a wide array of blockchain standards. Our expertise spans <b>ERC-20</b> Tokens, <b>ERC-721</b> NFTs, <b>ERC-3643</b> for RWA Security Tokens, and <b>ERC-6960</b> for asset fractionalization. We're also adept in <b>DeFi</b> and <b>Account Abstraction</b>, leveraging <b>ERC-4337</b> and <b>ERC-7579</b>."
                                                )
                                            }}></span>
                                    </div>
                                    {/* <p>
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            {' '}
                                            Evolving Blockchain:{' '}
                                        </span>{' '}
                                        <br />
                                        Propel into the digital age with our
                                        pioneering solutions. <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            Enhanced Security:
                                        </span>{' '}
                                        <br /> Advanced cryptography for
                                        uncompromised protection. <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            Smart Contracts & Account
                                            Abstraction:
                                        </span>{' '}
                                        <br />
                                        Streamlined transactions through
                                        functional smart contracts and
                                        innovative account abstraction.
                                        <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            NFTs & Tokenization:
                                        </span>{' '}
                                        <br />
                                        Generate dynamic, disruptive value for
                                        your business.
                                        <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            Agile dApps & Accounting:
                                        </span>{' '}
                                        <br />
                                        Decentralized applications
                                        revolutionizing financial management.
                                        <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            Innovative DAO & Governance:
                                        </span>{' '}
                                        <br />
                                        Enlightened administration for adaptive
                                        governance.
                                        <br />
                                        <span
                                            className='color-text'
                                            style={{ fontSize: 18 }}>
                                            Precision Oracles:
                                        </span>{' '}
                                        <br />
                                        Reliable data for automated, strategic
                                        decisions.
                                    </p> */}
                                    {/* <div className='skills'>
                                        <div className='skill-item'>
                                            <h6>
                                                Web Development <em>80%</em>
                                            </h6>
                                            <div className='skill-progress'>
                                                <div
                                                    className='progres'
                                                    data-value='90%'></div>
                                            </div>
                                        </div>
                                        <div className='skill-item'>
                                            <h6>
                                                iOS apps <em>75%</em>
                                            </h6>
                                            <div className='skill-progress'>
                                                <div
                                                    className='progres'
                                                    data-value='75%'></div>
                                            </div>
                                        </div>
                                        <div className='skill-item'>
                                            <h6>
                                                Discovery & Strategy{' '}
                                                <em>70%</em>
                                            </h6>
                                            <div className='skill-progress'>
                                                <div
                                                    className='progres'
                                                    data-value='80%'></div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div style={{ marginBottom: '30px' }}>
                                        <h2>
                                            {t(
                                                'Proven Innovation in Blockchain'
                                            )}
                                        </h2>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'With over 1,000 smart contracts deployed, <b>Solichain</b> is synonymous with secure and forward-thinking blockchain solutions. Our work empowers dApps and DEX platforms, underpinning our reputation for trust and innovation on a global scale.'
                                                )
                                            }}></span>
                                    </div>

                                    <div style={{ marginBottom: '30px' }}>
                                        <h2>{t('Your Bridge to Web3')}</h2>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    '<b>Solichain</b> ensures a frictionless foray into the blockchain world, with particular emphasis on RWAs and DeFi. Trust us to handle your projects with the utmost precision, making your transition into Web3 smooth and successful.'
                                                )
                                            }}></span>
                                    </div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <h2>
                                            {t(
                                                'Realize Your Blockchain Potential'
                                            )}
                                        </h2>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'Embark on your blockchain journey with Solichain. Our cutting-edge solutions are designed to turn your Web3 vision into a concrete reality, setting the stage for your success in the blockchain era.'
                                                )
                                            }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End About Section --> */}
            {/* <!-- Start Services Section --> */}
            <Services />
            {/* <!-- End Services Section --> */}
            {/* <!-- Start Overview Section --> */}
            <Overview />
            {/* <!-- End Overview Section --> */}
            {/* <!-- Start Overview Section --> */}
            {/* <section className='overview-section section-padding'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <div className='overview-image'>
                                <img
                                    src='assets/img/choose-2.png'
                                    alt='image'
                                />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='overview-content'>
                                <h2>Tech Soul for your Business</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                </p>
                                <ul className='features-list'>
                                    <li>
                                        {' '}
                                        <span className='active'>
                                            Discovery & Strategy
                                        </span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>UX/UI Design</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>iOS apps (Swift)</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Android apps (Java)</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Managed IT Service</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <span>Cloud Services</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- End Overview Section --> */}
            {/* <!-- Start Counter Section --> */}
            {/* <Counter /> */}
            {/* <!-- End Counter Section --> */}
            {/* <!-- Start Team Section --> */}
            {/* <Team /> */}
            {/* <!-- End Team Section --> */}
            {/* <!-- Start Testimonial Section --> */}
            {/* <Testimonial /> */}
            {/* <!-- End Testimonial Section --> */}
            {/* <!-- Start Hire Section --> */}
            <ContactUs />
            {/* <!-- End Hire Section --> */}
            {/* <!-- Start Blog Section --> */}
            <Blogs />
            {/* <!-- End Blog Section --> */}
            {/* <!-- Start Partner Logo Section --> */}
            {/* <Partnership /> */}
            {/* <!-- End Partner Logo Section --> */}
            {/* <!-- Start Footer Section --> */}
            <Footer />
            {/* <!-- End Footer Section --> */}
        </>
    );
}

export default Lending;
