import React from 'react';
import { useTranslation } from 'react-i18next';

function Counter() {
    const { t } = useTranslation();

    return (
        <section className='counter section-padding' id='counter'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 counter-item'>
                        <div className='single-counter'>
                            <div className='counter-contents'>
                                <h2>
                                    <span className='counter-number'>3</span>
                                    <span>+</span>
                                </h2>
                                <h3 className='counter-heading'>
                                    {t('ERC Standards Authored')}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 counter-item'>
                        <div className='single-counter'>
                            <div className='counter-contents'>
                                <h2>
                                    <span className='counter-number'>10</span>
                                    <span>+</span>
                                </h2>
                                <h3 className='counter-heading'>
                                    {t('Years of experience in Blockchain')}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 counter-item'>
                        <div className='single-counter'>
                            <div className='counter-contents'>
                                <h2>
                                    <span className='counter-number'>1000</span>
                                    <span>+</span>
                                </h2>
                                <h3 className='counter-heading'>
                                    {t('Smart Contracts Successfully Deployed')}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 counter-item'>
                        <div className='single-counter'>
                            <div className='counter-contents'>
                                <h2>
                                    <span className='counter-number'>25</span>
                                    <span>+</span>
                                </h2>
                                <h3 className='counter-heading'>
                                    {t('Innovative Projects Completed')}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    {
        /* <!-- End Counter Section --> */
    }
}

export default Counter;
