import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './english/translation.json';
import translationFrench from './french/translation.json';

const resources = {
    en: {
        translation: translationEnglish
    },
    fr: {
        translation: translationFrench
    }
};

i18next.use(initReactI18next).init({
    resources,
    lng: 'en' // default language
});

export default i18next;
