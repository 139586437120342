import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Lending from './components/template/Lending';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ContactUs from './components/template/contact-us';
// import AboutUs from './components/template/about-us';

import './translation/i18n'; // here 📌
import { useTranslation } from 'react-i18next';
import Navbar from './components/organisms/Navbar';

function App() {
    const { i18n } = useTranslation();
    const [activeLang, setActiveLang] = useState(
        localStorage.getItem('i18nextLng') || 'en'
    );

    useEffect(() => {
        changeLanguage(activeLang);
    }, []);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        setActiveLang(language);
        localStorage.setItem('i18nextLng', language);
    };

    return (
        <>
            <ToastContainer
                position='top-center'
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme='light'
            />
            <Navbar language={activeLang} onLanguageChange={changeLanguage} />
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Lending />} />
                    {/* <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact-us' element={<ContactUs />} /> */}
                    <Route path='*' element={<Lending />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
