import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

type ComponentProps = {
    language: string;
    onLanguageChange: (newLanguage: string) => void;
};

const Navbar: React.FC<ComponentProps> = ({ language, onLanguageChange }) => {
    const { t } = useTranslation();

    const [scrolled, setScrolled] = useState(false);

    const [activeLink, setActiveLink] = useState('');

    const handleClick = (link: string) => {
        setActiveLink(link);
    };

    useEffect(() => {
        if (scrollY >= 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;

            if (scrollY >= 200) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [activeNav, setActiveNav] = useState(false);

    function changeactiveNav() {
        setActiveNav(!activeNav);
    }

    return (
        <>
            <div className={`header ${scrolled ? 'active' : ''}`}>
                <div className='container'>
                    <img
                        src='assets/img/solichain-logo-bg-white.webp'
                        className='black-logo'
                        alt='logo'
                    />

                    <nav className={`nav-bar ${activeNav ? 'active' : ''}`}>
                        <ul className='nav-links-container'>
                            <li className='nav-li'>
                                <a
                                    href='#home'
                                    className={`nav-link ${
                                        activeLink === 'home' ? 'active' : ''
                                    }`}
                                    onClick={() => handleClick('home')}>
                                    <span className='link-span'>
                                        {t('Home')}
                                    </span>
                                </a>
                            </li>
                            <li className='nav-li'>
                                <a
                                    href='#about-us'
                                    className={`nav-link ${
                                        activeLink === 'about-us'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => handleClick('about-us')}>
                                    <span className='link-span'>
                                        {t('About Us')}
                                    </span>{' '}
                                </a>
                            </li>
                            {/* className={`dropdown-ul ${
                                        activeLang ? 'isactive' : ''
                                    }`} */}

                            <li className='nav-li'>
                                <a
                                    href='#contact-us'
                                    className={`nav-link ${
                                        activeLink === 'contact-us'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => handleClick('contact-us')}>
                                    <span className='link-span'>
                                        {t('Contact')}
                                    </span>
                                </a>
                            </li>
                            <li className='nav-li'>
                                <a
                                    href='#blog'
                                    className={`nav-link ${
                                        activeLink === 'blog' ? 'active' : ''
                                    }`}
                                    onClick={() => handleClick('blog')}>
                                    <span className='link-span'>
                                        {t('Blogs')}
                                    </span>
                                </a>
                            </li>
                            {/* <li>
                                <ToggleLangButton />
                            </li> */}
                        </ul>
                        {/* <div className='other-option'>
                            <a className='default-btn nav-btn-1' href='#0'>
                                Log In <span></span>
                            </a>
                            <a className='default-btn' href='#0'>
                                Get Quotes <span></span>
                            </a>
                        </div> */}
                        {/* // onClick={handleContainerClick}> */}
                    </nav>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <button
                            className='nav-open-btn'
                            onClick={changeactiveNav}
                            style={{ marginLeft: '15px' }}>
                            <span className='line line-1'></span>
                            <span className='line line-2'></span>
                            <span className='line line-3'></span>
                        </button>
                        <ButtonGroup aria-label='Language toggle'>
                            <Button
                                className={`lang-btn ${
                                    language === 'en' ? 'active' : ''
                                }`}
                                variant={
                                    language === 'en' ? 'primary' : 'light'
                                }
                                onClick={() => onLanguageChange('en')}>
                                EN
                            </Button>
                            <Button
                                className={`lang-btn ${
                                    language === 'fr' ? 'active' : ''
                                }`}
                                variant={
                                    language === 'fr' ? 'primary' : 'light'
                                }
                                onClick={() => onLanguageChange('fr')}>
                                FR
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <div
                className={`over-lay ${activeNav ? 'active' : ''}`}
                onClick={changeactiveNav}></div>
        </>
    );
};

export default Navbar;
