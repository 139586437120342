import React from 'react';
import { useTranslation } from 'react-i18next';

function Blogs() {
    const { t } = useTranslation();

    return (
        <section className='blog-section pt-70 pb-70' id='blog'>
            <div className='container'>
                <div className='section-title'>
                    <h2>{t('Recent Blog & Article')}</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <div className='blog-item'>
                            <div className='blog-image'>
                                <a href='#'>
                                    <img
                                        src='assets/img/blog/intents-centric-making-web3-user-friendly.webp'
                                        alt='image'
                                    />
                                </a>
                            </div>
                            <div className='single-blog-item'>
                                <div className='blog-content'>
                                    <h3>
                                        <a href='single-blog.html'>
                                            {t(
                                                'Intents-Centric: Making Web3 User-Friendly'
                                            )}
                                        </a>
                                    </h3>
                                    <p>
                                        {t(
                                            'Effortless Transactions and Smart Contracts'
                                        )}
                                    </p>

                                    <div className='blog-btn'>
                                        {' '}
                                        <a
                                            href='https://blog.solichain.com/the-power-of-intents-f3d536330382'
                                            className='default-btn blog-btn-one'>
                                            {t('Continue Reading')}{' '}
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='blog-item'>
                            <div className='blog-image'>
                                <a href='#'>
                                    <img
                                        src='assets/img/blog/T-REX.webp'
                                        alt='image'
                                    />
                                </a>
                            </div>
                            <div className='single-blog-item'>
                                <div className='blog-content'>
                                    <h3>
                                        <a href='#'>{t('T-REX Unleashed')}</a>
                                    </h3>
                                    <p>
                                        {t(
                                            'How ERC-3643 is Transforming Security Tokens and Real-World Assets'
                                        )}
                                    </p>

                                    <div className='blog-btn'>
                                        <a
                                            href='https://blog.solichain.com/t-rex-unleashed-how-erc-3643-is-transforming-security-tokens-and-real-world-assets-9312e4b64872'
                                            className='default-btn blog-btn-one'>
                                            {t('Continue Reading')}{' '}
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <div className='blog-item'>
                            <div className='blog-image'>
                                <a href='#'>
                                    <img
                                        src='assets/img/blog/solidity-1.0.0.webp'
                                        alt='image'
                                    />
                                </a>
                            </div>
                            <div className='single-blog-item'>
                                <div className='blog-content'>
                                    <h3>
                                        <a href='single-blog.html'>
                                            {t('Solidity 1.0.0')}{' '}
                                        </a>
                                    </h3>
                                    <p>{t('Next-Gen Smart Contracts')}</p>
                                    <div className='blog-btn'>
                                        {' '}
                                        <a
                                            href='https://blog.solichain.com/solidity-1-0-0-next-gen-smart-contracts-c5a94e3f72d6'
                                            className='default-btn blog-btn-one'>
                                            {t('Continue Reading')}{' '}
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    {
        /* <!-- End Blog Section --> */
    }
}

export default Blogs;
