// const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import 'react-international-phone/style.css';
import { toast } from 'react-toastify';

const ContactUsForm = () => {
    // const [validForm, setValidForm] = useState(false);
    const { t } = useTranslation();
    const form = useRef<HTMLFormElement>(null);

    const [isloading, setIsLoading] = useState(false);
    // const [formStatus, setFormStatus] = useState({ message: '', type: '' });

    const [user_name, setuser_name] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [touched, setTouched] = useState({
        user_name: false,
        email: false,
        subject: false,
        message: false
    });

    const isValidEmail = (email: string) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    };

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        e.preventDefault();

        if (!user_name || !isValidEmail(email) || !subject || !message) {
            setTouched({
                user_name: true,
                email: true,
                subject: true,
                message: true
            });
            setIsLoading(false);
            return;
        }

        if (
            !process.env.REACT_APP_SERVICE_KEY ||
            !process.env.REACT_APP_PUBLIC_KEY ||
            !process.env.REACT_APP_TEMPLATE_KEY
        ) {
            setIsLoading(false);
            toast.error(t('faild try again later'));
            return;
        }
        if (form.current !== null) {
            emailjs
                .sendForm(
                    process.env.REACT_APP_SERVICE_KEY,
                    process.env.REACT_APP_TEMPLATE_KEY,
                    form.current,
                    process.env.REACT_APP_PUBLIC_KEY
                )
                .then(
                    (result) => {
                        result;
                        setIsLoading(false);

                        setuser_name('');
                        setEmail('');
                        setSubject('');
                        setMessage('');
                        setTouched({
                            user_name: false,
                            email: false,
                            subject: false,
                            message: false
                        });
                        toast.success(t('Email sent successfully'));
                    },
                    (error) => {
                        console.log(error);

                        setIsLoading(false);
                        toast.error(t('faild try again later'));
                    }
                );
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailValid(isValidEmail(newEmail));
    };

    const handleEmailBlur = () => {
        setTouched({ ...touched, email: true });
        setEmailValid(isValidEmail(email));
    };

    return (
        <div className='contact-section section-padding'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 col-md-12 m-auto'>
                        <div className='contact-form contact-form-3'>
                            <form
                                id='contact-form'
                                className='form'
                                onSubmit={sendEmail}
                                ref={form}
                                noValidate>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                name='name'
                                                id='name'
                                                className={`form-control ${
                                                    touched.user_name &&
                                                    !user_name
                                                        ? 'invalid'
                                                        : ''
                                                }`}
                                                value={user_name}
                                                onChange={(e) => {
                                                    setuser_name(
                                                        e.target.value
                                                    );
                                                }}
                                                onBlur={() => {
                                                    setTouched({
                                                        ...touched,
                                                        user_name: true
                                                    });
                                                }}
                                                placeholder={t('Your Name')}
                                                required
                                                autoComplete='on'
                                            />
                                            {touched.user_name &&
                                                !user_name && (
                                                    <span
                                                        style={{
                                                            color: 'red'
                                                        }}>
                                                        {t('Name is required')}
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='form-group'>
                                            <input
                                                type='email'
                                                name='email'
                                                id='email'
                                                className={`form-control ${
                                                    touched.email && !emailValid
                                                        ? 'invalid'
                                                        : ''
                                                }`}
                                                value={email}
                                                onChange={handleEmailChange}
                                                onBlur={handleEmailBlur}
                                                placeholder={t('Your Email')}
                                                required
                                                autoComplete='on'
                                            />
                                            {touched.email && !emailValid && (
                                                <span style={{ color: 'red' }}>
                                                    {email.length === 0
                                                        ? t('Email is required')
                                                        : t(
                                                              'Invalid email pattern'
                                                          )}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className='form-group'>
                                            <select
                                                name='subject'
                                                id='subject'
                                                className={`form-control ${
                                                    touched.subject && !subject
                                                        ? 'invalid'
                                                        : ''
                                                }`}
                                                value={subject}
                                                onChange={(e) =>
                                                    setSubject(e.target.value)
                                                }
                                                onBlur={() =>
                                                    setTouched({
                                                        ...touched,
                                                        subject: true
                                                    })
                                                }
                                                required>
                                                <option value='' disabled>
                                                    {t('Select Subject')}
                                                </option>
                                                <option
                                                    value={t(
                                                        'Start a Project'
                                                    )}>
                                                    {t('Start a Project')}
                                                </option>
                                                <option
                                                    value={t('Partnerships')}>
                                                    {t('Partnerships')}
                                                </option>
                                                <option value={t('Careers')}>
                                                    {t('Careers')}
                                                </option>
                                                <option
                                                    value={t(
                                                        'Other Inquiries'
                                                    )}>
                                                    {t('Other Inquiries')}
                                                </option>
                                            </select>
                                            {touched.subject && !subject && (
                                                <span style={{ color: 'red' }}>
                                                    {t('Subject is required')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className='form-group'>
                                            <textarea
                                                name='message'
                                                id='message'
                                                className={`form-control ${
                                                    touched.message && !message
                                                        ? 'invalid'
                                                        : ''
                                                }`}
                                                value={message}
                                                onChange={(e) =>
                                                    setMessage(e.target.value)
                                                }
                                                onBlur={() =>
                                                    setTouched({
                                                        ...touched,
                                                        message: true
                                                    })
                                                }
                                                placeholder={t('Your Message')}
                                                required></textarea>
                                            {touched.message && !message && (
                                                <span style={{ color: 'red' }}>
                                                    {t('Message is required')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <button
                                            type='submit'
                                            disabled={isloading}
                                            className='default-btn'>
                                            {!isloading
                                                ? t('Get Started')
                                                : t('Loading...')}{' '}
                                            <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsForm;
