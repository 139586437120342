import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    return (
        <div className='home-area' id='home'>
            <div className='d-table'>
                <div className='d-table-cell'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12'>
                                <div className='main-banner-content'>
                                    <h5 className='color-text'>
                                        {t('Web3 innovation, delivered')}
                                    </h5>
                                    <h1>
                                        {t('Empowering Progress with')}
                                        <span
                                            className='color-text'
                                            style={{ paddingLeft: '10px' }}>
                                            {t('Web3 Solutions')}
                                        </span>
                                    </h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "Welcome to <b>Solichain</b>, where blockchain isn't just technology — it's our calling. We excel in crafting complete <b>Web3 solutions</b> for businesses ready to innovate. Our team specializes in end-to-end development, from <b>DeFi strategies</b> to deploying <b>smart contracts</b> and creating user-friendly <b>dApps</b>."
                                            )
                                        }}></p>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                'Embrace the future with <b>Solichain</b>, your partner in building a robust Web3 presence.'
                                            )
                                        }}></p>
                                    <div className='banner-btn'>
                                        <a
                                            href='#about-us'
                                            className='default-btn-one'
                                            style={{ marginRight: '5px' }}>
                                            {t('Learn More')}
                                            <span></span>
                                        </a>
                                        <a
                                            className='default-btn'
                                            href='#contact-us'>
                                            {t('Contact Us')}
                                            <span></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='banner-image'>
                                    <img
                                        src='assets/img/homeImage.webp'
                                        alt='image'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grey-cr'></div>
        </div>
    );
    {
        /* <!-- End Home Area --> */
    }
}

export default Home;
