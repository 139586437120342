import React from 'react';
import Service from '../molecules/Service';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation();

    {
        /* <!-- Start Services Section --> */
    }

    return (
        <section
            id='services'
            className='services-section pt-70 pb-100'
            style={{
                backgroundColor: 'rgb(215, 215, 215, 0.17)'
            }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6'>
                        <Service
                            iconClass='pe-7s-tools'
                            title={t('Web3 Development')}
                            // subTitle={t('Building Secure Smart Contracts')}
                            content={t(
                                "At Solichain, we're committed to <b>building secure smart contracts </b>and robust <b>Web3 infrastructures.</b> With the latest technology, we fortify your blockchain foundation."
                            )}
                            link='#0'
                        />
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <Service
                            title={t('Tech Innovation')}
                            iconClass='pe-7s-display1'
                            // subTitle={t('Tailored Blockchain Tech for Growth')}
                            content={t(
                                "Solichain stands for <b>tailored blockchain technology</b> that advances your business. Whether you're a startup or a corporate giant, we deliver<b> practical, innovative Web3 services.</b>"
                            )}
                            link='#0'
                        />
                    </div>
                    <div className='col-lg-4 col-md-6'>
                        <Service
                            iconClass='pe-7s-share'
                            title={t('Audit & Consulting')}
                            // subTitle={t('Enhancing Your Blockchain Journey')}
                            content={t(
                                'Trust in Solichain for <b>strategic consulting</b> that elevates your blockchain endeavors. We infuse transparency and expertise into every <b>Web3 project</b>, guiding you towards success.'
                            )}
                            link='#0'
                        />
                    </div>
                </div>
            </div>
        </section>
    );
    {
        /* <!-- End Services Section --> */
    }
}

export default Services;
