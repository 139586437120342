import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <section className='footer-area section-padding'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className='single-footer-widget'>
                            <div className='footer-heading'>
                                <h3>{t('About Us')}</h3>
                            </div>
                            <h5>{t('Innovative Web3 Digital Solutions')}</h5>
                            <p>
                                {t(
                                    'Harnessing blockchain to deliver exceptional quality and adaptability for your innovative projects.'
                                )}
                            </p>

                            <ul className='footer-social'>
                                <li>
                                    <a href='https://twitter.com/solichain'>
                                        {' '}
                                        <i className='fab fa-medium'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://twitter.com/solichain'>
                                        {' '}
                                        <i className='fab fa-twitter'></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href='#overview'>
                                        {' '}
                                        <i className='fab fa-pinterest'></i>
                                    </a>
                                </li> */}
                                <li>
                                    <a href='https://www.linkedin.com/company/solichain/'>
                                        {' '}
                                        <i className='fab fa-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className='single-footer-widget'>
                            <div className='footer-heading'>
                                <h3>{t('Our Services')}</h3>
                            </div>
                            <ul className='footer-quick-links'>
                                <li>
                                    {' '}
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>Smart Contract Development:</b> Building the core of decentralized applications.'
                                            )
                                        }}></a>
                                </li>
                                <li>
                                    {' '}
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>DeFi Solutions:</b> Crafting transparent financial services on the blockchain.'
                                            )
                                        }}></a>
                                </li>
                                <li>
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>Blockchain Integration:</b> Seamlessly integrating advanced blockchain technology into your business.'
                                            )
                                        }}></a>
                                </li>
                                <li>
                                    {' '}
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>dApp Engineering:</b> Creating decentralized applications for a new era of the internet.'
                                            )
                                        }}></a>
                                </li>
                                <li>
                                    {' '}
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>DAO Frameworks:</b> Establishing decentralized autonomous organizations for collective governance.'
                                            )
                                        }}></a>
                                </li>
                                <li>
                                    {' '}
                                    <a
                                        href='#overview'
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                '<b>NFT & Asset Tokenization:</b> Unlocking the value of assets in the digital world.'
                                            )
                                        }}></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className='single-footer-widget'>
                            <div className='footer-heading'>
                                <h3>{t('Contact Information')}</h3>
                            </div>
                            {/* <div className='footer-info-contact'>
                                <span>
                                    <strong>Phone:</strong>{' '}
                                    <a href='tel:0802235678'>
                                        +000 313 577 111
                                    </a>
                                </span>
                            </div> */}
                            <div className='footer-info-contact'>
                                <span>
                                    <strong>Email:</strong>{' '}
                                    <a href='mailto:contact@solichain.com'>
                                        contact@solichain.com
                                    </a>
                                </span>
                            </div>
                            <div className='footer-info-contact'>
                                <span style={{ width: '110%' }}>
                                    <strong>{t('Location')}:</strong> Cluster K,
                                    JLT, Dubai, United Arab Emirates
                                </span>
                            </div>
                            {/* <!-- Start Subscribe Area --> */}
                            {/* <form className='newsletter-form'>
                                <input
                                    type='email'
                                    className='input-newsletter'
                                    placeholder='Enter your email'
                                    name='EMAIL'
                                    required
                                    autoComplete='off'
                                />
                                <button className='default-btn' type='submit'>
                                    Subscribe <span></span>
                                </button>
                            </form> */}
                            {/* <!-- End Subscribe Area --> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;
