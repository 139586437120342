import React from 'react';
import ContactUsForm from './ContactUsForm';
import { useTranslation } from 'react-i18next';
import Counter from './Counter';
// import { useTranslation } from 'react-i18next';
// const { t } = useTranslation();

// import { Link } from 'react-router-dom';

function ContactUs() {
    const { t } = useTranslation();

    return (
        <div>
            <section className='hire-section' id='contact-us'>
                <div className='container'>
                    <div>
                        <Counter />
                    </div>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2 col-md-12'>
                            <div className='hire-content'>
                                <h2>{t('A project in mind ?')}</h2>
                                <p>
                                    {t(
                                        'Solichain is your partner in turning Web3 and blockchain ideas into reality. You envision, we create.'
                                    )}
                                </p>
                                <div className='hire-btn'>
                                    <a
                                        className='default-btn-one'
                                        href='mailto:contact@solichain.com'>
                                        {t('Contact Us')} <span></span>
                                    </a>
                                    <p>
                                        {t("Let's shape the future together.")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactUsForm />
        </div>
    );
}

export default ContactUs;
